/* tslint:disable */
/* eslint-disable */
/**
 * Dataland Backend API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../../../../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../../../../../../base';
// @ts-ignore
import type { DataMetaInformation } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { DataMetaInformationPatch } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { DataMetaInformationSearchFilter } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { DataTypeEnum } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { ErrorResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { NonSourceableInfo } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { NonSourceableInfoResponse } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
// @ts-ignore
import type { QaStatus } from '../../../../../../org/dataland/datalandfrontend/openApiClient/backend/model';
/**
 * MetaDataControllerApi - axios parameter creator
 * @export
 */
export const MetaDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The IDs of the data points contained in the dataset specified are returned as a map of strings to string.
         * @summary Retrieve a map of data point IDs the dataset is composed of to their corresponding technical ID.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainedDataPoints: async (dataId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('getContainedDataPoints', 'dataId', dataId)
            const localVarPath = `/metadata/{dataId}/data-points`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Meta info about a specific dataset registered by Dataland and identified by its data ID is retrieved.
         * @summary Look up meta info about a specific dataset.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataMetaInfo: async (dataId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('getDataMetaInfo', 'dataId', dataId)
            const localVarPath = `/metadata/{dataId}`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve information about the sourceability of datasets by the filters.
         * @summary Retrieve information about the sourceability of datasets
         * @param {string} [companyId] 
         * @param {DataTypeEnum} [dataType] 
         * @param {string} [reportingPeriod] 
         * @param {boolean} [nonSourceable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoOnNonSourceabilityOfDatasets: async (companyId?: string, dataType?: DataTypeEnum, reportingPeriod?: string, nonSourceable?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metadata/nonSourceable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }

            if (reportingPeriod !== undefined) {
                localVarQueryParameter['reportingPeriod'] = reportingPeriod;
            }

            if (nonSourceable !== undefined) {
                localVarQueryParameter['nonSourceable'] = nonSourceable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Meta info about datasets registered by Dataland can be retrieved.
         * @summary Search in Dataland for meta info about data.
         * @param {string} [companyId] 
         * @param {DataTypeEnum} [dataType] 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {Set<string>} [uploaderUserIds] 
         * @param {QaStatus} [qaStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfDataMetaInfo: async (companyId?: string, dataType?: DataTypeEnum, showOnlyActive?: boolean, reportingPeriod?: string, uploaderUserIds?: Set<string>, qaStatus?: QaStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }

            if (showOnlyActive !== undefined) {
                localVarQueryParameter['showOnlyActive'] = showOnlyActive;
            }

            if (reportingPeriod !== undefined) {
                localVarQueryParameter['reportingPeriod'] = reportingPeriod;
            }

            if (uploaderUserIds) {
                localVarQueryParameter['uploaderUserIds'] = Array.from(uploaderUserIds);
            }

            if (qaStatus !== undefined) {
                localVarQueryParameter['qaStatus'] = qaStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if a specific dataset is non-sourceable.
         * @summary Checks if a dataset is non-sourceable.
         * @param {string} companyId 
         * @param {DataTypeEnum} dataType 
         * @param {string} reportingPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDataNonSourceable: async (companyId: string, dataType: DataTypeEnum, reportingPeriod: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('isDataNonSourceable', 'companyId', companyId)
            // verify required parameter 'dataType' is not null or undefined
            assertParamExists('isDataNonSourceable', 'dataType', dataType)
            // verify required parameter 'reportingPeriod' is not null or undefined
            assertParamExists('isDataNonSourceable', 'reportingPeriod', reportingPeriod)
            const localVarPath = `/metadata/nonSourceable/{companyId}/{dataType}/{reportingPeriod}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"dataType"}}`, encodeURIComponent(String(dataType)))
                .replace(`{${"reportingPeriod"}}`, encodeURIComponent(String(reportingPeriod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provided fields of the meta data with the given dataId are updated.
         * @summary Update meta data of dataset selectively
         * @param {string} dataId 
         * @param {DataMetaInformationPatch} dataMetaInformationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDataMetaInfo: async (dataId: string, dataMetaInformationPatch: DataMetaInformationPatch, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataId' is not null or undefined
            assertParamExists('patchDataMetaInfo', 'dataId', dataId)
            // verify required parameter 'dataMetaInformationPatch' is not null or undefined
            assertParamExists('patchDataMetaInfo', 'dataMetaInformationPatch', dataMetaInformationPatch)
            const localVarPath = `/metadata/{dataId}`
                .replace(`{${"dataId"}}`, encodeURIComponent(String(dataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataMetaInformationPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Meta info about datasets registered by Dataland can be retrieved.
         * @summary Search in Dataland for meta info about data using multiple filters.
         * @param {Array<DataMetaInformationSearchFilter>} dataMetaInformationSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postListOfDataMetaInfoFilters: async (dataMetaInformationSearchFilter: Array<DataMetaInformationSearchFilter>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataMetaInformationSearchFilter' is not null or undefined
            assertParamExists('postListOfDataMetaInfoFilters', 'dataMetaInformationSearchFilter', dataMetaInformationSearchFilter)
            const localVarPath = `/metadata/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataMetaInformationSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A dataset is added with information on its sourceability.
         * @summary Adds a dataset with information on sourceability.
         * @param {NonSourceableInfo} nonSourceableInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNonSourceabilityOfADataset: async (nonSourceableInfo: NonSourceableInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nonSourceableInfo' is not null or undefined
            assertParamExists('postNonSourceabilityOfADataset', 'nonSourceableInfo', nonSourceableInfo)
            const localVarPath = `/metadata/nonSourceable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication default-bearer-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication default-oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "default-oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nonSourceableInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaDataControllerApi - functional programming interface
 * @export
 */
export const MetaDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetaDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * The IDs of the data points contained in the dataset specified are returned as a map of strings to string.
         * @summary Retrieve a map of data point IDs the dataset is composed of to their corresponding technical ID.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContainedDataPoints(dataId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContainedDataPoints(dataId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaDataControllerApi.getContainedDataPoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Meta info about a specific dataset registered by Dataland and identified by its data ID is retrieved.
         * @summary Look up meta info about a specific dataset.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataMetaInfo(dataId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataMetaInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataMetaInfo(dataId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaDataControllerApi.getDataMetaInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve information about the sourceability of datasets by the filters.
         * @summary Retrieve information about the sourceability of datasets
         * @param {string} [companyId] 
         * @param {DataTypeEnum} [dataType] 
         * @param {string} [reportingPeriod] 
         * @param {boolean} [nonSourceable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoOnNonSourceabilityOfDatasets(companyId?: string, dataType?: DataTypeEnum, reportingPeriod?: string, nonSourceable?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NonSourceableInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoOnNonSourceabilityOfDatasets(companyId, dataType, reportingPeriod, nonSourceable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaDataControllerApi.getInfoOnNonSourceabilityOfDatasets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Meta info about datasets registered by Dataland can be retrieved.
         * @summary Search in Dataland for meta info about data.
         * @param {string} [companyId] 
         * @param {DataTypeEnum} [dataType] 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {Set<string>} [uploaderUserIds] 
         * @param {QaStatus} [qaStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListOfDataMetaInfo(companyId?: string, dataType?: DataTypeEnum, showOnlyActive?: boolean, reportingPeriod?: string, uploaderUserIds?: Set<string>, qaStatus?: QaStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataMetaInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListOfDataMetaInfo(companyId, dataType, showOnlyActive, reportingPeriod, uploaderUserIds, qaStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaDataControllerApi.getListOfDataMetaInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Checks if a specific dataset is non-sourceable.
         * @summary Checks if a dataset is non-sourceable.
         * @param {string} companyId 
         * @param {DataTypeEnum} dataType 
         * @param {string} reportingPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isDataNonSourceable(companyId: string, dataType: DataTypeEnum, reportingPeriod: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isDataNonSourceable(companyId, dataType, reportingPeriod, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaDataControllerApi.isDataNonSourceable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provided fields of the meta data with the given dataId are updated.
         * @summary Update meta data of dataset selectively
         * @param {string} dataId 
         * @param {DataMetaInformationPatch} dataMetaInformationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDataMetaInfo(dataId: string, dataMetaInformationPatch: DataMetaInformationPatch, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataMetaInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDataMetaInfo(dataId, dataMetaInformationPatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaDataControllerApi.patchDataMetaInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Meta info about datasets registered by Dataland can be retrieved.
         * @summary Search in Dataland for meta info about data using multiple filters.
         * @param {Array<DataMetaInformationSearchFilter>} dataMetaInformationSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postListOfDataMetaInfoFilters(dataMetaInformationSearchFilter: Array<DataMetaInformationSearchFilter>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataMetaInformation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postListOfDataMetaInfoFilters(dataMetaInformationSearchFilter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaDataControllerApi.postListOfDataMetaInfoFilters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A dataset is added with information on its sourceability.
         * @summary Adds a dataset with information on sourceability.
         * @param {NonSourceableInfo} nonSourceableInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNonSourceabilityOfADataset(nonSourceableInfo: NonSourceableInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNonSourceabilityOfADataset(nonSourceableInfo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaDataControllerApi.postNonSourceabilityOfADataset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetaDataControllerApi - factory interface
 * @export
 */
export const MetaDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetaDataControllerApiFp(configuration)
    return {
        /**
         * The IDs of the data points contained in the dataset specified are returned as a map of strings to string.
         * @summary Retrieve a map of data point IDs the dataset is composed of to their corresponding technical ID.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainedDataPoints(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getContainedDataPoints(dataId, options).then((request) => request(axios, basePath));
        },
        /**
         * Meta info about a specific dataset registered by Dataland and identified by its data ID is retrieved.
         * @summary Look up meta info about a specific dataset.
         * @param {string} dataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataMetaInfo(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataMetaInformation> {
            return localVarFp.getDataMetaInfo(dataId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve information about the sourceability of datasets by the filters.
         * @summary Retrieve information about the sourceability of datasets
         * @param {string} [companyId] 
         * @param {DataTypeEnum} [dataType] 
         * @param {string} [reportingPeriod] 
         * @param {boolean} [nonSourceable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoOnNonSourceabilityOfDatasets(companyId?: string, dataType?: DataTypeEnum, reportingPeriod?: string, nonSourceable?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<NonSourceableInfoResponse>> {
            return localVarFp.getInfoOnNonSourceabilityOfDatasets(companyId, dataType, reportingPeriod, nonSourceable, options).then((request) => request(axios, basePath));
        },
        /**
         * Meta info about datasets registered by Dataland can be retrieved.
         * @summary Search in Dataland for meta info about data.
         * @param {string} [companyId] 
         * @param {DataTypeEnum} [dataType] 
         * @param {boolean} [showOnlyActive] 
         * @param {string} [reportingPeriod] 
         * @param {Set<string>} [uploaderUserIds] 
         * @param {QaStatus} [qaStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfDataMetaInfo(companyId?: string, dataType?: DataTypeEnum, showOnlyActive?: boolean, reportingPeriod?: string, uploaderUserIds?: Set<string>, qaStatus?: QaStatus, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataMetaInformation>> {
            return localVarFp.getListOfDataMetaInfo(companyId, dataType, showOnlyActive, reportingPeriod, uploaderUserIds, qaStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if a specific dataset is non-sourceable.
         * @summary Checks if a dataset is non-sourceable.
         * @param {string} companyId 
         * @param {DataTypeEnum} dataType 
         * @param {string} reportingPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isDataNonSourceable(companyId: string, dataType: DataTypeEnum, reportingPeriod: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.isDataNonSourceable(companyId, dataType, reportingPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * Provided fields of the meta data with the given dataId are updated.
         * @summary Update meta data of dataset selectively
         * @param {string} dataId 
         * @param {DataMetaInformationPatch} dataMetaInformationPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDataMetaInfo(dataId: string, dataMetaInformationPatch: DataMetaInformationPatch, options?: RawAxiosRequestConfig): AxiosPromise<DataMetaInformation> {
            return localVarFp.patchDataMetaInfo(dataId, dataMetaInformationPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * Meta info about datasets registered by Dataland can be retrieved.
         * @summary Search in Dataland for meta info about data using multiple filters.
         * @param {Array<DataMetaInformationSearchFilter>} dataMetaInformationSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postListOfDataMetaInfoFilters(dataMetaInformationSearchFilter: Array<DataMetaInformationSearchFilter>, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataMetaInformation>> {
            return localVarFp.postListOfDataMetaInfoFilters(dataMetaInformationSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * A dataset is added with information on its sourceability.
         * @summary Adds a dataset with information on sourceability.
         * @param {NonSourceableInfo} nonSourceableInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNonSourceabilityOfADataset(nonSourceableInfo: NonSourceableInfo, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postNonSourceabilityOfADataset(nonSourceableInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetaDataControllerApi - interface
 * @export
 * @interface MetaDataControllerApi
 */
export interface MetaDataControllerApiInterface {
    /**
     * The IDs of the data points contained in the dataset specified are returned as a map of strings to string.
     * @summary Retrieve a map of data point IDs the dataset is composed of to their corresponding technical ID.
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApiInterface
     */
    getContainedDataPoints(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: string; }>;

    /**
     * Meta info about a specific dataset registered by Dataland and identified by its data ID is retrieved.
     * @summary Look up meta info about a specific dataset.
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApiInterface
     */
    getDataMetaInfo(dataId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataMetaInformation>;

    /**
     * Retrieve information about the sourceability of datasets by the filters.
     * @summary Retrieve information about the sourceability of datasets
     * @param {string} [companyId] 
     * @param {DataTypeEnum} [dataType] 
     * @param {string} [reportingPeriod] 
     * @param {boolean} [nonSourceable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApiInterface
     */
    getInfoOnNonSourceabilityOfDatasets(companyId?: string, dataType?: DataTypeEnum, reportingPeriod?: string, nonSourceable?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<NonSourceableInfoResponse>>;

    /**
     * Meta info about datasets registered by Dataland can be retrieved.
     * @summary Search in Dataland for meta info about data.
     * @param {string} [companyId] 
     * @param {DataTypeEnum} [dataType] 
     * @param {boolean} [showOnlyActive] 
     * @param {string} [reportingPeriod] 
     * @param {Set<string>} [uploaderUserIds] 
     * @param {QaStatus} [qaStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApiInterface
     */
    getListOfDataMetaInfo(companyId?: string, dataType?: DataTypeEnum, showOnlyActive?: boolean, reportingPeriod?: string, uploaderUserIds?: Set<string>, qaStatus?: QaStatus, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataMetaInformation>>;

    /**
     * Checks if a specific dataset is non-sourceable.
     * @summary Checks if a dataset is non-sourceable.
     * @param {string} companyId 
     * @param {DataTypeEnum} dataType 
     * @param {string} reportingPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApiInterface
     */
    isDataNonSourceable(companyId: string, dataType: DataTypeEnum, reportingPeriod: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Provided fields of the meta data with the given dataId are updated.
     * @summary Update meta data of dataset selectively
     * @param {string} dataId 
     * @param {DataMetaInformationPatch} dataMetaInformationPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApiInterface
     */
    patchDataMetaInfo(dataId: string, dataMetaInformationPatch: DataMetaInformationPatch, options?: RawAxiosRequestConfig): AxiosPromise<DataMetaInformation>;

    /**
     * Meta info about datasets registered by Dataland can be retrieved.
     * @summary Search in Dataland for meta info about data using multiple filters.
     * @param {Array<DataMetaInformationSearchFilter>} dataMetaInformationSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApiInterface
     */
    postListOfDataMetaInfoFilters(dataMetaInformationSearchFilter: Array<DataMetaInformationSearchFilter>, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataMetaInformation>>;

    /**
     * A dataset is added with information on its sourceability.
     * @summary Adds a dataset with information on sourceability.
     * @param {NonSourceableInfo} nonSourceableInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApiInterface
     */
    postNonSourceabilityOfADataset(nonSourceableInfo: NonSourceableInfo, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * MetaDataControllerApi - object-oriented interface
 * @export
 * @class MetaDataControllerApi
 * @extends {BaseAPI}
 */
export class MetaDataControllerApi extends BaseAPI implements MetaDataControllerApiInterface {
    /**
     * The IDs of the data points contained in the dataset specified are returned as a map of strings to string.
     * @summary Retrieve a map of data point IDs the dataset is composed of to their corresponding technical ID.
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApi
     */
    public getContainedDataPoints(dataId: string, options?: RawAxiosRequestConfig) {
        return MetaDataControllerApiFp(this.configuration).getContainedDataPoints(dataId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Meta info about a specific dataset registered by Dataland and identified by its data ID is retrieved.
     * @summary Look up meta info about a specific dataset.
     * @param {string} dataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApi
     */
    public getDataMetaInfo(dataId: string, options?: RawAxiosRequestConfig) {
        return MetaDataControllerApiFp(this.configuration).getDataMetaInfo(dataId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve information about the sourceability of datasets by the filters.
     * @summary Retrieve information about the sourceability of datasets
     * @param {string} [companyId] 
     * @param {DataTypeEnum} [dataType] 
     * @param {string} [reportingPeriod] 
     * @param {boolean} [nonSourceable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApi
     */
    public getInfoOnNonSourceabilityOfDatasets(companyId?: string, dataType?: DataTypeEnum, reportingPeriod?: string, nonSourceable?: boolean, options?: RawAxiosRequestConfig) {
        return MetaDataControllerApiFp(this.configuration).getInfoOnNonSourceabilityOfDatasets(companyId, dataType, reportingPeriod, nonSourceable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Meta info about datasets registered by Dataland can be retrieved.
     * @summary Search in Dataland for meta info about data.
     * @param {string} [companyId] 
     * @param {DataTypeEnum} [dataType] 
     * @param {boolean} [showOnlyActive] 
     * @param {string} [reportingPeriod] 
     * @param {Set<string>} [uploaderUserIds] 
     * @param {QaStatus} [qaStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApi
     */
    public getListOfDataMetaInfo(companyId?: string, dataType?: DataTypeEnum, showOnlyActive?: boolean, reportingPeriod?: string, uploaderUserIds?: Set<string>, qaStatus?: QaStatus, options?: RawAxiosRequestConfig) {
        return MetaDataControllerApiFp(this.configuration).getListOfDataMetaInfo(companyId, dataType, showOnlyActive, reportingPeriod, uploaderUserIds, qaStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if a specific dataset is non-sourceable.
     * @summary Checks if a dataset is non-sourceable.
     * @param {string} companyId 
     * @param {DataTypeEnum} dataType 
     * @param {string} reportingPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApi
     */
    public isDataNonSourceable(companyId: string, dataType: DataTypeEnum, reportingPeriod: string, options?: RawAxiosRequestConfig) {
        return MetaDataControllerApiFp(this.configuration).isDataNonSourceable(companyId, dataType, reportingPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provided fields of the meta data with the given dataId are updated.
     * @summary Update meta data of dataset selectively
     * @param {string} dataId 
     * @param {DataMetaInformationPatch} dataMetaInformationPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApi
     */
    public patchDataMetaInfo(dataId: string, dataMetaInformationPatch: DataMetaInformationPatch, options?: RawAxiosRequestConfig) {
        return MetaDataControllerApiFp(this.configuration).patchDataMetaInfo(dataId, dataMetaInformationPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Meta info about datasets registered by Dataland can be retrieved.
     * @summary Search in Dataland for meta info about data using multiple filters.
     * @param {Array<DataMetaInformationSearchFilter>} dataMetaInformationSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApi
     */
    public postListOfDataMetaInfoFilters(dataMetaInformationSearchFilter: Array<DataMetaInformationSearchFilter>, options?: RawAxiosRequestConfig) {
        return MetaDataControllerApiFp(this.configuration).postListOfDataMetaInfoFilters(dataMetaInformationSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A dataset is added with information on its sourceability.
     * @summary Adds a dataset with information on sourceability.
     * @param {NonSourceableInfo} nonSourceableInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaDataControllerApi
     */
    public postNonSourceabilityOfADataset(nonSourceableInfo: NonSourceableInfo, options?: RawAxiosRequestConfig) {
        return MetaDataControllerApiFp(this.configuration).postNonSourceabilityOfADataset(nonSourceableInfo, options).then((request) => request(this.axios, this.basePath));
    }
}

